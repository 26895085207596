import { FC } from "react";
import { BasicProps } from "./types/BasicProps";
import displayHealthFactor from "../logic/helpers/displayHealthFactor";
import { healthFactorDescription } from "../literals";
import { HealthFactorCircle } from "./HealthFactorCircle";
import classNames from "classnames";
import { Info } from "./Info";
import TokenIcon from "./icons/TokenIcon";
import { ICurrency } from "logic/types/currency/ICurrency";

export type DataItem = {
  label?: string;
  value?: bigint | string;
  type: "health-factor" | "currency" | "percentage" | "string";
  currency?: ICurrency;
};

type TextSize = "text-xs" | "text-sm" | "text-base" | "text-lg" | "text-xl";

type Props = BasicProps & {
  item: DataItem;
  labelStyles?: string;
  valueStyles?: string;
  valueTextSize?: TextSize;
  hideHfInfo?: boolean;
};

const DataField: FC<Props> = (props) => {
  const {
    item: { type, value, label, currency },
    labelStyles = "text-xs font-bold",
    valueStyles = "justify-center font-normal",
    valueTextSize = "text-base",
    className,
    hideHfInfo = false,
  } = props;

  const isHealthFactor = type === "health-factor";

  const displayValue =
    value === undefined
      ? "-.---"
      : isHealthFactor
      ? displayHealthFactor(value as bigint)
      : type === "percentage"
      ? `${value.toString()}%`
      : type === "string" && typeof value === "string"
      ? value
      : currency?.formatAmount(value as bigint);

  const iconHeight = getHeightFromTextSize(valueTextSize);

  return (
    <div className={classNames("flex flex-col", className)}>
      {label && (
        <div className="flex justify-center items-center gap-1">
          <span className={classNames(labelStyles, "w-full")}>{label}</span>
          {isHealthFactor && !hideHfInfo && (
            <Info>
              <Info.Description>{healthFactorDescription}</Info.Description>
            </Info>
          )}{" "}
        </div>
      )}
      <div className={classNames(valueStyles, "flex items-center gap-1.5")}>
        <span className={valueTextSize}>{displayValue}</span>
        {isHealthFactor && (
          <HealthFactorCircle healthFactor={value as bigint} />
        )}
        {currency && <TokenIcon currency={currency} className={iconHeight} />}
      </div>
    </div>
  );
};

export default DataField;

function getHeightFromTextSize(textSize: TextSize): string {
  switch (textSize) {
    case "text-xs":
      return "h-3 w-3";
    case "text-sm":
      return "h-4 w-3";
    case "text-base":
      return "h-[14px] w-[14px]";
    default:
      return "h-6 w-6";
  }
}
