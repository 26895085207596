"use client";
import { type FC, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { CtaSecondary, RichText, CtaPrimary } from "..";
import { withModal } from "./shared";
import { ModalProps } from "../types/ModalProps";
import { useErrorAlert } from "../../logic/hooks";
import { handleErrors } from "../../errors";
import { externalWalletModule } from "../../clients/verisModule";
import { app } from "app.config";

export const ModalSwitchNetwork: FC<ModalProps> = withModal((props) => {
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);

  const statics = {
    title: "Change Network",
    successButton: "Change network",
    dismissButton: "Cancel",
    feedbacks: {
      userRejectedRequestError: "User rejected request",
      unknownError: "Unknown error switching network, please try again",
      pendingSwitchTransactionError: "already pending request",
    },
  };
  const { title, dismissButton, successButton } = statics;

  const { toggleModal } = props;

  const handleSwitchNetwork = async () => {
    try {
      await externalWalletModule.switchChain();

      setTimeout(() => {
        toggleModal(false);
      }, 1500);
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  return (
    <>
      <div
        className="h-fit top-1/2 -translate-y-1/2 bg-secondary relative border-glow-sm max-w-[calc(100vw-24px-24px)] w-96 overflow-hidden
           border-2 rounded-3xl xs:px-6 px-5 pb-6 pt-8 xs:pb-8"
      >
        <button
          className="w-10 h-10 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5 text-white"
          onClick={() => toggleModal(false)}
        >
          <IoMdClose className="w-full h-full" />
        </button>
        <h5 className="xs:mb-8 mb-5 text-center text-base xs:text-lg text-white font-bold">
          {title}
        </h5>
        <div className="w-80 text-center text-xs xs:text-sm max-w-full xs:mb-8 mb-5">
          <RichText value={legalText} className="text-white" />
        </div>
        {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
        <div className="max-w-full xs:flex xs:flex-row-reverse justify-center items-center">
          <CtaPrimary
            className="max-xs:w-full max-xs:mb-4"
            onClick={handleSwitchNetwork}
          >
            {successButton}
          </CtaPrimary>
          <CtaSecondary
            className="xs:mr-4 max-xs:w-full"
            onClick={() => toggleModal(false)}
          >
            {dismissButton}
          </CtaSecondary>
        </div>
      </div>
    </>
  );
});

const legalText = `To be able to mint, please switch to ${
  app.CHAIN.name === "goerli"
    ? "Goerli"
    : app.CHAIN.name === "sepolia"
    ? "Sepolia"
    : "Ethereum"
} network`;
