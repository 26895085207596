import { Address } from "viem";
import { type ConfigChain, app } from "../app.config";
import { CONSTANTS, PushAPI } from "@pushprotocol/restapi";
import { externalWalletModule } from "../clients/verisModule";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";

class PushNotificationsModule {
  private pushChannel: Address;
  private chain: ConfigChain;
  private pushAPI?: PushAPI;

  constructor(chain: ConfigChain, pushChannel: Address) {
    this.chain = chain;
    this.pushChannel = pushChannel;
  }

  private get channelAddress() {
    return `eip155:${this.chain.id}:${this.pushChannel}`;
  }

  private get eipPrefix() {
    return `eip155:${this.chain.id}`;
  }

  private getUserEip(userAddress: Address) {
    return `${this.eipPrefix}${userAddress}`;
  }

  private get env() {
    return this.chain.name !== "mainnet"
      ? CONSTANTS.ENV.STAGING
      : CONSTANTS.ENV.PROD;
  }

  private async getPushAPI() {
    if (!externalWalletModule.client) {
      throw new Error("Client not connected");
    }

    if (
      this.pushAPI &&
      // @ts-ignore
      equalIgnoreCase(this.pushAPI.account!, externalWalletModule.address!)
    ) {
      return this.pushAPI;
    }

    const pushAPI = await PushAPI.initialize(externalWalletModule.client, {
      env: this.env,
    });

    return (this.pushAPI = pushAPI);
  }

  async isUserSubscribed() {
    const pushAPI = await this.getPushAPI();

    const subscriptions: { channel: Address; user_settings: string }[] =
      await pushAPI.notification.subscriptions();

    const isChannelInSubscriptions = subscriptions.some(({ channel }) =>
      equalIgnoreCase(channel, this.pushChannel)
    );

    return isChannelInSubscriptions;
  }

  async subscribe() {
    const pushAPI = await this.getPushAPI();

    const response = await pushAPI.notification.subscribe(this.channelAddress);

    if (response instanceof Error || response.status !== 204) {
      throw new Error("Failed to subscribe");
    }

    return;
  }

  async unsubscribe() {
    const pushAPI = await this.getPushAPI();

    const response = await pushAPI.notification.unsubscribe(
      this.channelAddress
    );

    if (response instanceof Error || response.status !== 204) {
      throw new Error("Failed to unsubscribe");
    }

    return;
  }
}

export default new PushNotificationsModule(
  app.CHAIN,
  app.CONTRACT_ADDRESSES.pushChannel
);
