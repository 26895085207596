import { Address } from "viem";
import { ERC721Contract } from "../contracts/ERC721Contract";
import CryptopunksContract from "../contracts/CryptopunksContract";
import { cryptopunksContract } from "../contracts";
import isCryptopunk from "./helpers/nfts/isCryptopunk";
import TheGraph from "../thegraph";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../clients/verisModule";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { app } from "app.config";

class FaucetModule {
  private erc721Contracts: ERC721Contract[];
  private cryptopunksContract: CryptopunksContract = cryptopunksContract;

  constructor(faucetAddresses: Address[]) {
    this.erc721Contracts = faucetAddresses
      .filter((address) => !isCryptopunk(address))
      .map((address) => new ERC721Contract(address));
  }

  async getAvailableNftsForAddress(
    mintAddress: Address,
    walletAddress: Address
  ): Promise<{ availableNfts: number; hasAvailableNftsToMint: boolean }> {
    return isCryptopunk(mintAddress)
      ? this.getCryptopunksAvailable()
      : this.getErc721Available(mintAddress, walletAddress);
  }

  private async getCryptopunksAvailable() {
    const punksRemainingToAssign =
      await this.cryptopunksContract.punksRemainingToAssign();

    const arePunksRemainingToAssign = punksRemainingToAssign > BigInt(0);

    return {
      availableNfts: arePunksRemainingToAssign ? 1 : 0,
      hasAvailableNftsToMint: arePunksRemainingToAssign,
    };
  }

  private async getErc721Available(
    mintAddress: Address,
    walletAddress: Address
  ) {
    const faucetContract = this.getErc721Contract(mintAddress);

    if (!faucetContract)
      throw new Error(`No faucet contract found for address ${mintAddress}`);

    const [balanceOfAddress, MAX_SUPPLY, totalSupply] = await Promise.all([
      faucetContract.balanceOf(walletAddress),
      faucetContract.maxSupply(),
      faucetContract.totalSupply(),
    ]);

    const availableNftsForUser =
      BigInt(app.MAX_NFTS_FOR_A_USER) - balanceOfAddress;

    const availableNftsInContract = MAX_SUPPLY - totalSupply;

    const availableNfts =
      availableNftsInContract > availableNftsForUser
        ? availableNftsForUser
        : availableNftsInContract;

    return {
      availableNfts: Number(availableNfts),
      hasAvailableNftsToMint: availableNfts > 0,
    };
  }

  async mintFaucet(
    collection: Address,
    amount: number = 1,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return isCryptopunk(collection)
      ? this.mintCryptopunk(options)
      : this.mintErc721(collection, amount, options);
  }

  private async getNextPunkIndexToAssign() {
    const data = await TheGraph.client.getAllPunks();

    const punks = data.punks;

    for (let i = 0; i < punks.length; i++) {
      if (Number(punks[i].id) !== i) {
        return i;
      }
    }

    return punks.length;
  }

  private mintErc721(
    collection: Address,
    amount: number,
    options?: OptionsWriteMethod
  ) {
    const faucetContract = this.getErc721Contract(collection);

    return faucetContract.mint(externalWalletModule.address!, amount, options);
  }

  private async mintCryptopunk(options?: OptionsWriteMethod) {
    const punkIndex = await this.getNextPunkIndexAvailable();

    return this.cryptopunksContract.getPunk(BigInt(punkIndex), options);
  }

  private getErc721Contract(mintAddress: Address): ERC721Contract {
    const faucetContract = this.erc721Contracts.find((contract) =>
      equalIgnoreCase(contract.address!, mintAddress)
    );

    if (!faucetContract)
      throw new Error(`No faucet contract found for address ${mintAddress}`);

    return faucetContract;
  }

  private async getNextPunkIndexAvailable(): Promise<number> {
    const data = await TheGraph.client.getAllPunks();

    const punksSorted = data.punks.sort((a, b) => Number(a.id) - Number(b.id));

    for (let i = 0; i < punksSorted.length; i++) {
      if (Number(punksSorted[i].id) !== i) {
        return i;
      }
    }

    return punksSorted.length;
  }
}

export default new FaucetModule(
  app.FAUCET_COLLECTIONS.map(({ address }) => address)
);
