"use client";
import { IoMdClose } from "react-icons/io";
import { type FC, useState, useEffect } from "react";
import { RichText, CtaPrimary } from "..";
import { IconWalletConnect } from "../icons";
import { ErrorMessage, withModal } from "./shared";
import Image from "next/image";
import { ModalProps } from "../types/ModalProps";
import { lifeCycleLiterals } from "../../literals";
import { Connector } from "@wagmi/core";
import { externalWalletModule } from "../../clients/verisModule";

export const ModalConnectWallets: FC<ModalProps> = withModal(
  (props: ModalProps) => {
    const { toggleModal } = props;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [connectors, setConnectors] = useState<readonly Connector[]>([]);

    useEffect(() => {
      setConnectors(externalWalletModule.getConnectors());
    }, []);

    const handleConnect = async (connector: Connector) => {
      try {
        await externalWalletModule.connect(connector);

        toggleModal(false);
      } catch (err) {
        if (err?.code === 4001)
          return setErrorMessage(lifeCycleLiterals.general.userRejectedTx);
        else if (err?.code === -32002)
          return setErrorMessage(
            "Already waiting for user interaction, please check your wallet."
          );
        else setErrorMessage("unexpected error while connecting");
      }
    };

    return (
      <>
        <div
          className="h-fit top-1/2 -translate-y-1/2 bg-secondary relative border-glow-sm max-w-[calc(100vw-24px-24px)] overflow-hidden
        border-2 rounded-3xl xs:px-6 px-5 pb-6 pt-8 xs:pb-8"
        >
          <button
            className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
            onClick={() => toggleModal(false)}
          >
            <IoMdClose className="w-full h-full text-white" />
          </button>
          <h5 className="xs:mb-8 mb-5 text-center text-base xs:text-lg font-bold text-white">
            Connect a Wallet
          </h5>
          <div className="grid gap-4 xs:mb-8 mb-5 w-60 max-w-full mx-auto">
            {connectors.map(
              (connector, i) =>
                connector.name !== "Injected" && (
                  <CtaPrimary
                    key={connector.id + i.toString()}
                    className="w-full mx-auto !flex items-center justify-center"
                    onClick={() => handleConnect(connector)}
                  >
                    <div className="relative w-6 h-6 mr-2 flex items-center justify-center">
                      {connector.icon && (
                        <Image
                          src={connector.icon}
                          width={24}
                          height={24}
                          alt={connector.name}
                        />
                      )}
                      {connector.name === "WalletConnect" && (
                        <IconWalletConnect />
                      )}
                    </div>
                    <span>{connector.name}</span>
                  </CtaPrimary>
                )
            )}
          </div>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <div className="w-80 text-left text-2xs xs:text-xs max-w-full text-white">
            <RichText value="Wallets are provided by External Providers and by selecting you agree to Terms of those Providers. Your access to the wallet might be reliant on the External Provider being operational." />
          </div>
        </div>
      </>
    );
  }
);
