import { Address } from "viem";
import UnlockdService from "./UnlockdService";
import { externalWalletModule } from "../clients/verisModule";

export default async function loginOnUnlockdApi(
  address: Address
): Promise<string> {
  const message = await UnlockdService.getAuthMessage(
    address.toLowerCase() as Address
  );

  const signature = await signMessage(message);

  return UnlockdService.sendSignature(address, signature);
}

function signMessage(message: string): Promise<string> {
  return externalWalletModule.client!.signMessage({
    account: externalWalletModule.client!.account!.address,
    message,
  });
}
