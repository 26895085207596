"use client";
import React, { FC, FormEvent, useEffect, useMemo, useState } from "react";
import { CtaSecondary, CtaPrimary, HealthFactorCircle } from "../..";
import { useErrorAlert } from "../../../logic/hooks";
import useAlertNotification from "../../hooks/useAlertNotification";
import { generalLiterals, lifeCycleLiterals } from "../../../literals";
import NftImage from "../../images/NftImage";
import { GenericModal } from "../GenericModal";
import LoadingSpinner from "../../LoadingSpinner";
import { handleErrors } from "../../../errors";
import { Loan } from "../../../logic/types/loan/Loan";
import DataField from "../../DataField";
import { useModalInstantSellItems } from "./helpers";
import displayHealthFactor from "../../../logic/helpers/displayHealthFactor";
import { Address } from "viem";
import { useWeb3ModalGuard } from "../shared";
import { ModalProps } from "components/types/ModalProps";
import TokenIcon from "components/icons/TokenIcon";
import { INft } from "logic/types/nft/INft";
import track from "logic/track";

const {
  subheader,
  title,
  amountToReceiveLabel,
  noOffersDescription,
  backCta,
  hfLabel,
  sellCta,
  sellCtaOnHfLow,
} = generalLiterals.modals.instantSell;

type Props = ModalProps & {
  loan: Loan;
  nft: INft;
  onSold?: (loanId: Address, nft: INft, amountRepaid?: bigint) => void;
};

export const ModalInstantSell: FC<Props> = (props: Props) => {
  const { toggleModal, loan, nft, isOpen, onSold } = props;
  const [isModalLoaded, setModalLoaded] = useState<boolean>(false);
  const [sellIsLoading, setSellIsLoading] = useState<boolean>(false);
  const [canBeSold, setCanBeSold] = useState<boolean>();
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const [, openAlertNotification] = useAlertNotification();
  const [reservoirBid, setReservoirBid] = useState<{
    amount: bigint;
    id: string;
    netAmount: bigint;
  } | null>();
  const dataFieldItems = useModalInstantSellItems(
    nft,
    loan,
    reservoirBid && reservoirBid.amount
  );
  const { amountToReceive, amountToRepay } = useMemo<{
    amountToReceive?: bigint;
    amountToRepay?: bigint;
  }>(() => {
    const result = { amountToReceive: undefined, amountToRepay: undefined };

    if (canBeSold !== true || !reservoirBid) {
      return result;
    }

    const amountToReceive = loan.calculateAmuntToReceiveOnInstantSell(
      nft,
      reservoirBid.netAmount
    );

    if (amountToReceive <= BigInt(0))
      return {
        amountToReceive: BigInt(0),
        amountToRepay: reservoirBid.netAmount,
      };

    return {
      amountToReceive,
      amountToRepay: reservoirBid.netAmount - amountToReceive,
    };
  }, [canBeSold, reservoirBid, loan.currentBorrowAmount]);

  const healthFactor = useMemo<bigint>(
    () =>
      !reservoirBid || amountToRepay === undefined
        ? BigInt(0)
        : loan.calculateHealthFactor({
            collateral: -nft.valuation,
            debt: -amountToRepay,
          }),
    [loan, reservoirBid, nft, amountToRepay]
  );

  const primaryButtonSettings = useMemo(
    () =>
      canBeSold === undefined
        ? { label: sellCta, isDisabled: true }
        : canBeSold
        ? {
            label: sellCta,
            isDisabled: false,
          }
        : { label: sellCtaOnHfLow, isDisabled: true },
    [canBeSold]
  );

  useEffect(() => {
    handleGetReservoirBid();
  }, []);

  useEffect(() => {
    if (reservoirBid !== undefined) {
      const canBeSold =
        reservoirBid === null
          ? false
          : loan.calculateIfInstantSellIsPossible(nft, reservoirBid.amount);

      setCanBeSold(canBeSold);
    }
  }, [reservoirBid]);

  useEffect(() => {
    if (canBeSold !== undefined) {
      setModalLoaded(true);
    }
  }, [canBeSold]);

  const handleGetReservoirBid = async () => {
    const reservoirBid = await nft.getReservoirBid();

    setReservoirBid(reservoirBid);
  };

  const handleSell = useWeb3ModalGuard(undefined, async () => {
    try {
      track.click("loan_sell_click_sell");

      await loan.instantSell(nft, {
        onServerSignPending: () => {
          setSellIsLoading(true);
          openAlertNotification(
            "info",
            lifeCycleLiterals.instantSell.onServerSignPending,
            5000000
          );
        },
        onSignaturePending: () => {
          openAlertNotification(
            "info",
            lifeCycleLiterals.instantSell.onSignPending,
            5000000
          );
        },
        onLoading: () => {
          openAlertNotification(
            "info",
            lifeCycleLiterals.instantSell.onTxPending,
            5000000
          );
        },
      });

      track.event("loan_management", "loan_sell_success");

      onSold?.(loan.id, nft, amountToRepay);

      openAlertNotification(
        "success",
        lifeCycleLiterals.instantSell.onSuccess,
        5000
      );

      toggleModal(false);
    } catch (err) {
      track.event("loan_management", "loan_sell_fail");

      setSellIsLoading(false);
      setError(handleErrors(err, "instantSell"));
    }
  });

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!error && reservoirBid && !sellIsLoading && canBeSold) {
      handleSell();
    }
  };

  return (
    <GenericModal
      onSubmit={handleSubmit}
      isOpen={isOpen}
      toggleModal={toggleModal}
      headerTitle={title}
      headerSubtitle={subheader}
    >
      <div className="mt-5 border-2 border-white rounded-xl py-3.5 pr-[30px] pl-7 flex justify-center items-center gap-10">
        <NftImage
          className="w-[55px] h-[55px] rounded-[10px] shrink-0"
          src={nft.image}
          alt={nft.alt}
        />
        <div className="grow-[1] grid grid-cols-4 gap-x-3">
          {dataFieldItems.map((item) => (
            <DataField key={item.label} className="items-start" item={item} />
          ))}
        </div>
      </div>

      {!isModalLoaded && (
        <div className="mt-5 h-12 flex items-center justify-center">
          <LoadingSpinner className="w-10 h-10" />
        </div>
      )}

      {isModalLoaded && (
        <>
          <div className="mt-5 w-full flex flex-col gap-2.5 items-center">
            {amountToReceive !== undefined && (
              <div className="flex items-center gap-1">
                <span>{amountToReceiveLabel}</span>
                <span className="font-bold">
                  {nft.currency.formatAmount(amountToReceive)}
                </span>
                <TokenIcon currency={nft.currency} className="w-4 h-4" />
              </div>
            )}

            {reservoirBid !== null && (
              <div className="flex items-center gap-1">
                <span>{hfLabel}</span>
                <span className="font-bold">
                  {displayHealthFactor(healthFactor)}
                </span>
                <HealthFactorCircle healthFactor={healthFactor} />
              </div>
            )}
          </div>

          {reservoirBid === null && (
            <div className="mt-4 text-myred">{noOffersDescription}</div>
          )}

          {sellIsLoading && (
            <div className="mt-5 h-12 flex items-center justify-center">
              <LoadingSpinner className="w-10 h-10" />
            </div>
          )}

          <div className="mt-5 flex justify-center gap-6">
            <CtaSecondary
              className="w-40 px-14"
              onClick={() => toggleModal(false)}
              type="button"
            >
              {backCta}
            </CtaSecondary>
            {reservoirBid !== null && (
              <CtaPrimary
                className="w-40 px-14"
                disabled={primaryButtonSettings.isDisabled}
                type="submit"
              >
                {primaryButtonSettings.label}
              </CtaPrimary>
            )}
          </div>
        </>
      )}
    </GenericModal>
  );
};

export default ModalInstantSell;
