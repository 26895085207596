"use client";
import { type FC, createContext, useState, useEffect, useContext } from "react";
import { BasicProps } from "../types/BasicProps";
import { useCustomWallet } from "./WalletProvider";
import { useErrorAlert } from "../../logic/hooks";
import { handleErrors } from "../../errors";
import { externalWalletModule } from "../../clients/verisModule";
import currenciesModule from "logic/CurrenciesModule";

type BalanceContextProps = {
  eth: bigint | undefined;
  otherCurrencies?: { name: string; balance: bigint }[];
  forceBalanceUpdate: () => void;
};

export const BalanceContext = createContext<BalanceContextProps>({
  eth: undefined,
  otherCurrencies: undefined,
  forceBalanceUpdate: () => {},
});

const BalanceProvider: FC<BasicProps> = ({ children }) => {
  const { address } = useCustomWallet();
  const [eth, setEth] = useState<bigint>();
  const [otherCurrencies, setOtherCurrencies] =
    useState<{ name: string; balance: bigint }[]>();
  const [sensor, toggleBalance] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);

  useEffect(() => {
    if (address) {
      handleGetBalances();

      return () => {
        setEth(undefined);
        setOtherCurrencies(undefined);
      };
    }
  }, [address, sensor]);

  const handleGetBalances = async () => {
    try {
      const [eth, ...otherCurrenciesBalance] = await Promise.all([
        externalWalletModule.getEthBalance(),
        ...currenciesModule.erc20Currencies.map((currency) =>
          externalWalletModule.getErc20Balance(currency.address)
        ),
      ]);

      setEth(eth);
      setOtherCurrencies(
        otherCurrenciesBalance.map((balance, index) => ({
          name: currenciesModule.erc20Currencies[index].name,
          balance,
        }))
      );
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  const handleUpdateBalance = () => {
    toggleBalance(!sensor);
  };

  return (
    <BalanceContext.Provider
      value={{
        eth,
        otherCurrencies,
        forceBalanceUpdate: handleUpdateBalance,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

const useWalletBalance = () => {
  return useContext(BalanceContext);
};

export { BalanceProvider, useWalletBalance };

export function useTokenBalance(name: string) {
  const isEth = name === "eth";
  const { eth, otherCurrencies, forceBalanceUpdate } =
    useContext(BalanceContext);
  const [balance, setBalance] = useState<bigint>();

  useEffect(() => {
    if (isEth) {
      setBalance(eth);
    } else if (otherCurrencies) {
      const currency = otherCurrencies.find(
        (currency) => currency.name === name
      );

      if (currency) {
        setBalance(currency.balance);
      } else {
        setBalance(undefined);
      }
    } else {
      setBalance(undefined);
    }
  }, [otherCurrencies, eth]);

  return {
    balance,
    forceBalanceUpdate,
  };
}
