"use client";
import { type FC, FormEvent, useEffect, useState, useMemo } from "react";
import { CtaSecondary, CtaPrimary } from "../..";
import { useCustomWallet } from "../../providers";
import useAlertNotification from "../../hooks/useAlertNotification";
import { handleErrors } from "../../../errors";
import {
  approveInWalletLiteral,
  generalLiterals,
  lifeCycleLiterals,
} from "../../../literals";
import { MarketItem } from "../../../logic/types";
import marketplaceModule, { Discount } from "../../../logic/MarketplaceModule";
import { isLockeysHolder } from "../../../logic/helpers";
import { GenericModal } from "../GenericModal";
import { WayToPayRadioOptions } from "../modal-bid/ModalBid";
import Input from "../shared/Input";
import SquareInput from "../../marketplace/list-for-sale/SquareInput";
import { IconGrocery } from "../../icons/IconGrocery";
import { IconLoan } from "../../icons/IconLoan";
import { useAllowance, useErrorAlert } from "../../../logic/hooks";
import NftImage from "../../images/NftImage";
import {
  useModalBuyoutDataItems,
  useWalletToDepositSelection,
} from "./helpers";
import classNames from "classnames";
import DataField from "../../DataField";
import BorrowAmountAndHealthFactor from "../shared/BorrowAmountAndHealthFactor";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import LoadingSpinner from "../../LoadingSpinner";
import { Address } from "viem";
import { deployUnlockdWallet } from "../../../logic/wallet";
import { MarketItemType } from "../../../contracts/MarketContract";
import { useWeb3ModalGuard } from "../shared";
import { useTokenBalance } from "../../providers/BalanceProvider";
import { app } from "app.config";
import TokenIcon from "components/icons/TokenIcon";
import { calculateHealthFactor } from "logic/helpers/math";
import { SelectionDropdown } from "../shared/selection-dropdown";
import track from "logic/track";
import InputErrorMessage from "../shared/InputErrorMessage";
import { ModalProps } from "components/types/ModalProps";

type Props = ModalProps & {
  marketItem: MarketItem;
  wasRemoved: boolean;
  onBought?: (itemId: Address) => void;
};

enum Status {
  CREATE_WALLET,
  APPROVE,
  BUY,
  SUCCESS,
  REDEEMED,
}

const {
  title,
  subheader,
  payFullPriceLabel,
  payLaterLabel,
  ctaPrimaryApprove,
  ctaPrimaryBuyNow,
  ctaPrimaryCreateWallet,
  ctaPrimaryNotEnoughBalance,
  ctaPrimaryConfirmLoan,
  initialPaymentInputLabel,
} = generalLiterals.modals.buyNow;

export const ModalBuyNow: FC<Props> = (props) => {
  const { marketItem, toggleModal, wasRemoved, isOpen, onBought } = props;
  const { address, client, hasSmartAddress, onUnlockdWalletCreated } =
    useCustomWallet();
  const [discount, setDiscount] = useState<Discount>();
  const { balance, forceBalanceUpdate } = useTokenBalance(
    marketItem.currency.name
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [radioSelected, setRadioSelected] = useState<WayToPayRadioOptions>(
    WayToPayRadioOptions.FULL_PRICE
  );
  const [initialPayment, setInitialPayment] = useState<bigint>(BigInt(0));
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const [errorOnInitialPayment, setErrorOnInitialPayment] =
    useState<Error | null>(null);
  const [status, setStatus] = useState<Status>(Status.APPROVE);
  const [, openAlertNotification] = useAlertNotification();

  const { dropdownShown, options, claimOnUWallet } =
    useWalletToDepositSelection(radioSelected, !!hasSmartAddress);

  const amountToPay = useMemo<bigint | undefined>(
    () =>
      radioSelected === WayToPayRadioOptions.FULL_PRICE
        ? marketItem.buyNowPrice
        : initialPayment,
    [marketItem.buyNowPrice, radioSelected, initialPayment]
  );

  const { handleApprove: _handleApprove, isApproved } = useAllowance({
    amount: amountToPay,
    spender: app.CONTRACT_ADDRESSES.unlockd,
    erc20Address: marketItem.currency.address,
  });

  const minInitialPayment = useMemo<bigint>(
    () =>
      marketItem.buyNowPrice === undefined
        ? BigInt(0)
        : marketItem.calculateMinInitialPayment(marketItem.buyNowPrice),
    [marketItem.buyNowPrice, radioSelected]
  );

  const showDiscount = useMemo<boolean>(
    () =>
      !!discount &&
      ((marketItem.isItemAuctioned && discount.loanAuctioned !== 0) ||
        (marketItem.isItemListed && discount.loanListed !== 0)),
    []
  );

  const amountToBorrow = useMemo<bigint>(
    () =>
      initialPayment !== undefined
        ? marketItem.buyNowPrice! - initialPayment
        : BigInt(0),
    [initialPayment, marketItem.buyNowPrice]
  );

  const dataFieldItems = useModalBuyoutDataItems(
    radioSelected,
    marketItem,
    amountToBorrow,
    minInitialPayment
  );

  const healthFactor = useMemo<bigint | undefined>(
    () =>
      radioSelected === WayToPayRadioOptions.FULL_PRICE ||
      amountToBorrow === undefined
        ? undefined
        : calculateHealthFactor(
            marketItem.nft.valuation,
            amountToBorrow,
            marketItem.nft.liquidationThreshold
          ),
    [radioSelected, marketItem, amountToBorrow]
  );

  const discountLiteral = useMemo<string>(() => {
    if (!discount) return "";

    const percentage =
      marketItem.type === MarketItemType.TYPE_LIQUIDATION_AUCTION
        ? discount.loanAuctioned
        : discount.loanListed;

    return `You qualify for a ${percentage} discount!`;
  }, [discount]);

  const ctaPrimarySettings = useMemo<{
    literal: string;
    isDisabled: boolean;
  }>(() => {
    const isDisabled =
      isLoading ||
      !(balance !== undefined && balance > marketItem.buyNowPrice!);

    const literal =
      balance !== undefined && balance < marketItem.buyNowPrice!
        ? ctaPrimaryNotEnoughBalance
        : status === Status.CREATE_WALLET
        ? ctaPrimaryCreateWallet
        : status === Status.APPROVE
        ? ctaPrimaryApprove
        : radioSelected === WayToPayRadioOptions.FULL_PRICE
        ? ctaPrimaryBuyNow
        : ctaPrimaryConfirmLoan;

    return { isDisabled, literal };
  }, [status, balance, radioSelected, isLoading]);

  const bidInputStyle = useMemo<string>(
    () => (!!errorOnInitialPayment ? "border-myred" : ""),
    [errorOnInitialPayment]
  );

  // handle status
  useEffect(() => {
    if (radioSelected === WayToPayRadioOptions.TAKE_LOAN && !hasSmartAddress) {
      setStatus(Status.CREATE_WALLET);
    } else if (isApproved) {
      setStatus(Status.BUY);
    } else {
      setStatus(Status.APPROVE);
    }
  }, [isApproved, marketItem.buyNowPrice, hasSmartAddress, radioSelected]);

  useEffect(() => {
    radioSelected === WayToPayRadioOptions.TAKE_LOAN &&
      runInitialPaymentValidation(initialPayment, radioSelected);
  }, [radioSelected, initialPayment, balance]);

  useEffect(() => {
    if (address) {
      handleGetDiscount();

      return () => {
        setDiscount(undefined);
      };
    }
  }, [address]);

  // useUpdateEffect(() => {
  //   if (wasRemoved && status === Status.TO_BUY) {
  //     openAlertNotification("info", removedLoanLiteral, 5000);

  //     setStatus(Status.REDEEMED);
  //   }
  // }, [wasRemoved]);

  const handleRadioChange = (value: string) =>
    setRadioSelected(value as WayToPayRadioOptions);

  const handleGetDiscount = async () => {
    if (await isLockeysHolder(address!)) {
      setDiscount(await marketplaceModule.getDiscount());
    }
  };

  const handleInitialPaymentChange = (amountSelected: bigint): void => {
    setInitialPayment(amountSelected);
  };

  const runInitialPaymentValidation = (
    amountSelected: bigint,
    radioSelected: WayToPayRadioOptions
  ) => {
    if (radioSelected === WayToPayRadioOptions.TAKE_LOAN) {
      if (amountSelected < minInitialPayment) {
        setErrorOnInitialPayment(
          new Error(lifeCycleLiterals.buyNow.initialPayLessThanMin)
        );

        return;
      } else if (marketItem.buyNowPrice !== undefined) {
        if (amountSelected > marketItem.buyNowPrice) {
          setErrorOnInitialPayment(
            new Error(lifeCycleLiterals.buyNow.initialPayExceedsPrice)
          );

          return;
        } else if (amountSelected === marketItem.buyNowPrice) {
          setErrorOnInitialPayment(
            new Error(lifeCycleLiterals.buyNow.initialPayEqualPrice)
          );

          return;
        }
      }
    }

    setErrorOnInitialPayment(null);
  };

  const handleCreateWallet = async () => {
    try {
      if (address) {
        const safeAddress = await deployUnlockdWallet(address, {
          onSignaturePending: () => {
            setLoading(true);
            openAlertNotification(
              "info",
              lifeCycleLiterals.createWallet.onSignPending,
              5000000
            );
          },
          onLoading: () => {
            openAlertNotification(
              "info",
              lifeCycleLiterals.createWallet.onTxPending,
              5000000
            );
          },
        });

        onUnlockdWalletCreated?.(safeAddress);
        openAlertNotification(
          "success",
          lifeCycleLiterals.createWallet.onSuccess,
          5000
        );
      }
    } catch (err) {
      setError(handleErrors(err, "createWallet"));
    } finally {
      forceBalanceUpdate();
      setLoading(false);
    }
  };

  const handleApprove = async () => {
    try {
      await _handleApprove(undefined, {
        onSignaturePending: () => {
          openAlertNotification("info", approveInWalletLiteral, 50000);
          setLoading(true);
        },
      });
    } catch (err) {
      setError(handleErrors(err));
    } finally {
      setLoading(false);
      forceBalanceUpdate();
    }
  };

  const handleBuyNow = useWeb3ModalGuard(undefined, async () => {
    if (claimOnUWallet !== undefined && marketItem.buyNowPrice !== undefined) {
      try {
        const amountToPay =
          radioSelected === WayToPayRadioOptions.FULL_PRICE
            ? marketItem.buyNowPrice
            : initialPayment;

        const amountOfDebt =
          radioSelected === WayToPayRadioOptions.TAKE_LOAN
            ? marketItem.buyNowPrice - initialPayment
            : BigInt(0);

        await marketItem.buyNow(amountToPay!, amountOfDebt, claimOnUWallet, {
          onServerSignPending: () => {
            setLoading(true);
            openAlertNotification(
              "info",
              lifeCycleLiterals.buyNow.onServerSignPending,
              5000000
            );
          },
          onSignaturePending: () => {
            openAlertNotification(
              "info",
              lifeCycleLiterals.buyNow.onSignPending,
              5000000
            );
          },
          onLoading: () => {
            openAlertNotification(
              "info",
              lifeCycleLiterals.buyNow.onTxPending,
              5000000
            );
          },
        });

        if (radioSelected === WayToPayRadioOptions.FULL_PRICE)
          track.event("purchase", "mktp_buy_fullprice_success");

        openAlertNotification(
          "success",
          lifeCycleLiterals.buyNow.onSuccess,
          5000
        );
        onBought?.(marketItem.id!);
        toggleModal(false);
      } catch (err) {
        if (radioSelected === WayToPayRadioOptions.FULL_PRICE)
          track.event("purchase", "mktp_buy_fullprice_fail");

        setError(handleErrors(err, "buyNow"));
      } finally {
        forceBalanceUpdate();
        setLoading(false);
      }
    }
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!isLoading && client && address) {
      if (status === Status.CREATE_WALLET) {
        handleCreateWallet();
      } else if (status === Status.APPROVE) {
        handleApprove();
      } else {
        if (
          radioSelected === WayToPayRadioOptions.FULL_PRICE ||
          !errorOnInitialPayment
        ) {
          if (radioSelected === WayToPayRadioOptions.FULL_PRICE) {
            track.click("mktp_buy_fullprice_click_buynow");
          }

          handleBuyNow();
        }
      }
    }
  };

  return (
    <GenericModal
      onSubmit={handleSubmit}
      isOpen={isOpen}
      toggleModal={toggleModal}
      headerTitle={title}
      headerSubtitle={subheader}
    >
      {isLoading ? (
        <div className="my-24 h-12 flex items-center justify-center">
          <LoadingSpinner className="w-10 h-10" />
        </div>
      ) : (
        <>
          <div className="mt-3 flex items-center gap-1">
            <span>Buy now for</span>
            <span className="font-bold">
              {marketItem.currency.formatAmount(marketItem.buyNowPrice!)}
            </span>
            <TokenIcon currency={marketItem.currency} className="w-4 h-4" />
          </div>

          {showDiscount && (
            <div className="mt-2.5 px-[22px] py-3 border border-mygreen rounded-2xl flex gap-2 items-center">
              <AiOutlineExclamationCircle
                className="w-3.5 h-3.5"
                color="#65FF3F"
              />
              <span className="text-xs">{discountLiteral}</span>
            </div>
          )}

          <div className="mt-3 flex justify-center gap-3.5">
            <SquareInput
              className="w-[123px] h-[89px]"
              Icon={IconGrocery}
              isSelected={radioSelected === WayToPayRadioOptions.FULL_PRICE}
              label={payFullPriceLabel}
              onSelected={handleRadioChange}
              value={WayToPayRadioOptions.FULL_PRICE}
              type="radio"
              circleHidden={true}
            />
            <SquareInput
              className="w-[123px] h-[89px]"
              Icon={IconLoan}
              isSelected={radioSelected === WayToPayRadioOptions.TAKE_LOAN}
              label={payLaterLabel}
              onSelected={handleRadioChange}
              value={WayToPayRadioOptions.TAKE_LOAN}
              type="radio"
              circleHidden={true}
            />
          </div>

          <div className="mt-3 w-full border-2 border-white rounded-xl py-3.5 pr-[30px] pl-7 flex justify-center items-center gap-10">
            <NftImage
              className="w-[55px] h-[55px] rounded-[10px] shrink-0"
              src={marketItem.nft.image}
              alt={marketItem.nft.alt}
            />
            <div className="grow-[1] grid grid-cols-3 gap-x-10 gap-y-3">
              {dataFieldItems.map((item) => (
                <DataField
                  key={item.label}
                  className={classNames(
                    "items-start overflow-hidden whitespace-nowrap",
                    // dataFieldStyles
                    "w-[140px]"
                  )}
                  item={item}
                />
              ))}
            </div>
          </div>

          {radioSelected === WayToPayRadioOptions.TAKE_LOAN && (
            <div className="mt-2 w-full flex flex-col">
              <Input
                className={classNames("w-full", bidInputStyle)}
                nameInput={initialPaymentInputLabel}
                label={initialPaymentInputLabel}
                value={initialPayment}
                defaultValue={initialPayment}
                // max={max}
                maxButtonHidden={true}
                onChange={handleInitialPaymentChange}
                currency={marketItem.currency}
              />
              <div className="mt-2 h-2.5">
                {errorOnInitialPayment && (
                  <InputErrorMessage error={errorOnInitialPayment} />
                )}
              </div>
            </div>
          )}

          {radioSelected === WayToPayRadioOptions.TAKE_LOAN && (
            <BorrowAmountAndHealthFactor
              className="mt-2 gap-1"
              currency={marketItem.currency}
              borrowAmount={amountToBorrow}
              healthFactor={healthFactor!}
            />
          )}

          {dropdownShown && (
            <div className="mt-2.5 w-full">
              <SelectionDropdown
                heightClassName="h-[80px]"
                className="w-full"
                options={options}
                title="Select a wallet to withdraw to"
              />
            </div>
          )}
        </>
      )}

      <div className="mt-5 flex justify-center gap-6">
        <CtaSecondary
          className="w-[190px]"
          type="button"
          onClick={() => toggleModal(false)}
        >
          Cancel
        </CtaSecondary>
        <CtaPrimary
          className="w-[190px]"
          disabled={ctaPrimarySettings.isDisabled}
          type="submit"
        >
          {ctaPrimarySettings.literal}
        </CtaPrimary>
      </div>
    </GenericModal>
  );
};
